import { useCallback, useState, useEffect } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Layout from '../../../layouts/global/Layout';
import { useClients } from './useClients';
import { IClient } from '../../../interfaces/client';
import SearchClients from '../../../components/form/SearchClients';
import ClientListCard from '../../../feature/Clients/ClientListCard';
import PrimaryButtonWithIcon from '../../../components/global/ui/buttons/PrimaryButtonWithIcon';
import AddNewClient from '../../../feature/Clients/AddNewClient';
import PageTitle from '../../../components/global/PageTitle';
import { useQuery } from 'react-query';
import _ from 'lodash';

export default function Clients() {
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');

  const [addClientOpen, setAddClientOpen] = useState<boolean>(false);

  const { listClients, loading: clientsLoading } = useClients();

  // Debounce searchKey updates
  const debounceSearch = useCallback(
    _.debounce((search) => setSearchKey(search), 1000),
    []
  );

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['clients', page, searchKey],
    queryFn: () => listClients({ page, search: searchKey }),
    keepPreviousData: true
  });

   const hasPermission = data?.msg.some((client: IClient) => client.has_permission);


  const handleSearchChange = (search: any) => {
    debounceSearch(search);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const totalPages = data?.additional?.pagination?.total_pages || 1;

  // Function to render page numbers with ellipsis
const renderPageNumbers = () => {
  const pageNumbers = [];

  // Calculate start and end page numbers
  let startPage = Math.max(1, page - 1);
  const endPage = Math.min(totalPages, startPage + 2);

  // Adjust startPage if we're near the end
  if (endPage - startPage < 2) {
    startPage = Math.max(1, endPage - 2);
  }

  // Add the page numbers to the list
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  // Add ellipses if there are more pages
  if (startPage > 1) {
    pageNumbers.unshift('...');
    pageNumbers.unshift(1);
  }

  if (endPage < totalPages) {
    pageNumbers.push('...');
    pageNumbers.push(totalPages);
  }

  return pageNumbers.map((pageNumber, index) => {
    if (pageNumber === '...') {
      return (
        <li key={index}>
          <a
            href="#"
            className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border-t border-b border-gray-300 hover:bg-gray-100 hover:text-gray-700"
          >
            {pageNumber}
          </a>
        </li>
      );
    }

    return (
      <li key={index}>
        <a
          href="#"
          onClick={() => handlePageChange(pageNumber)}
          className={`flex items-center justify-center px-4 h-10 leading-tight ${
            page === pageNumber
            ? 'text-emerald-600 border border-r-2 border-emerald-300 bg-emerald-50 hover:bg-emerald-100 hover:text-emerald-700 '
            : 'text-gray-500 bg-white border-t border-b border-gray-300 hover:bg-gray-100 hover:text-gray-700'
          }`}
        >
          {pageNumber}
        </a>
      </li>
    );
  });
};

  // Function to refetch data when page changes
  useEffect(() => {
    refetch();
  }, [page, refetch]);

  return (
    <Layout>
      {addClientOpen && <AddNewClient closeModal={() => setAddClientOpen(false)} />}
      <PageTitle title={'Clients & Users'} />
      <div>
        {isLoading ? (
          <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
            <LoadingSpinner color={'text-[#008160]'} />
          </div>
        ) : (
          <div>
            {data && data.msg && (
              <>
                <div className={'flex flex-col lg:flex-row justify-between gap-5'}>
                  <div
                    className={
                      'flex flex-col lg:flex-row gap-3 items-start lg:items-center lg:w-[20vw]'
                    }>
                    <SearchClients onSearchChange={handleSearchChange} />
                    <div className={'flex gap-2 items-center'}>
                      <span>Total: </span>
                      <span className={'font-medium'}>
                        {data.additional?.pagination?.total_records || 0}
                      </span>
                    </div>
                  </div>
                  {data && data.msg.length > 0 && (
                    <div className={'hidden lg:flex'}>
                      <PrimaryButtonWithIcon
                        handler={() => setAddClientOpen(true)}
                        title={'Add a user'}
                        icon={<i className="fi fi-rr-plus text-xs"></i>}
                      />
                    </div>
                  )}
                </div>
                <div className={'my-3 grid gap-2 pr-2'}>
                  <div
                    className={
                      'hidden lg:grid grid-cols-1 lg:grid-cols-12 gap-2 lg:gap-5 px-3 py-2 text-sm font-medium bg-gray-100 mb-1'
                    }>
                    <div className={'col-span-4 flex flex-col'}>
                      <span>Full name</span>
                    </div>
                    <div className={'col-span-3'}>
                      <span>Username</span>
                    </div>
                    <div className={'col-span-1'}>
                      <span>User ID</span>
                    </div>
                    <div className={'col-span-1'}>
                      <span>Status</span>
                    </div>
                    <div className={'col-span-3 flex gap-2'}>
                      <span>Created</span>
                    </div>
                  </div>
                  <div className={'flex flex-col gap-2 h-[63vh] overflow-auto slim-scroll'}>
                    {data &&
                      data.msg.map((item: IClient, index: number) => (
                        <ClientListCard data={item} key={index} />
                      ))}
                  </div>
                </div>
                <div className="flex items-center justify-end -bottom-5 sticky bg-white px-3">
  <nav aria-label="Page navigation example">
    <ul className="flex items-center -space-x-px h-10 text-base">
      <li>
        <a
          href="#"
          onClick={() => handlePageChange(page - 1)}
          className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white rounded-l border border-r-2 border-gray-300 hover:bg-gray-100 hover:text-gray-700  rounded-s-lg  ${
            page === 1 ? 'cursor-not-allowed opacity-50' : ''
          }`}
          aria-disabled={page === 1}
        >
          <svg
            className="w-3 h-3 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
          <span className="ml-2">Previous</span>
        </a>
      </li>
      {renderPageNumbers()}
      <li>
        <a
          href="#"
          onClick={() => handlePageChange(page + 1)}
          className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white rounded-r border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 ${
            page === totalPages ? 'cursor-not-allowed opacity-50' : ''
          }`}
          aria-disabled={page === totalPages}
        >
          <span className="mr-2">Next</span>
          <svg
            className="w-3 h-3 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
        </a>
      </li>
    </ul>
  </nav>
</div>

              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}
