import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../util/global';
import { useVerifyWebsite } from '../../../pages/main/Setup/useVerifyWebsite';
import OnboardingProgressBar from './OnboardingProgressBar';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import { isMobile } from 'react-device-detect';
import { routes } from '../../../constants/routes';
import { AMPLITUDE_EVENTS, ORGANIZATION_ID } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import InstallationHelp from '../../SnippetInstallation/InstallationHelp';
import WordpressWoocommerce from '../../SnippetInstallation/Installation/WordpressWoocommerce';
import BigCommerce from '../../SnippetInstallation/Installation/BigCommerce';
import OtherPlatforms from '../../SnippetInstallation/Installation/OtherPlatforms';
import Shopify from '../../SnippetInstallation/Installation/Shopify';
import WebflowSquarespace from '../../SnippetInstallation/Installation/WebflowSquarespace';
import ThirdPartyBuilder from '../../SnippetInstallation/Installation/ThirdPartyBuilder';
import { trackAmplitudeEvent } from '../../../util/amplitudeService';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { IUserWebsite } from '../../../interfaces/website';
import { useAppSettings } from '../../../pages/main/PlatformSettings/useAppSettings';
import axios from 'axios'; 

export default function SnippetInstallationVerification({
  changeStep,
  trackingDetails,
  selectedPageBuilderStatus,
  data
}: {
  data: IUserWebsite,
  changeStep: (s: number) => void;
  trackingDetails: any;
  selectedPageBuilderStatus: string | null;
}) {
  const [message, setMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [platformChangeShow, setPlatformShow] = useState<boolean>(false);
  const [snippetShow, setSnippetShow] = useState<number>(1);
  const { updateWebsiteSettings } = useAppSettings();

  const { fetchOrganizationData, profile } = useContext(OrganizationContext);

  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const navigate = useNavigate();

  const {
    verifySite,
    loading: verifySiteLoading,
    errorMessage: verifySiteError
  } = useVerifyWebsite();

  const query = useQuery();

  const handleVerifySite = async () => {
    setErrorMessage(null);
    setSnippetShow(2);
    try {
      const siteId = query.get('siteId');
      const url = query.get('url');
  
      if (siteId && url) {
        // Original verification logic
        const res = await verifySite({
          websiteUrl: url,
          siteId: siteId
        });
  
        // New functionality: Send data to pageinsight endpoint
        try {
          await axios.post('https://dashboard.heatmap.com/pageinsight/v1/', {
            url: url,
            idsite: parseInt(siteId, 10)
          });
          console.log('Data sent to pageinsight endpoint successfully');
        } catch (pageinsightError) {
          console.error('Error sending data to pageinsight:', pageinsightError);
        }
  
        if (res?.data.result === 'success') {
          setMessage(res?.data.msg);
          fetchOrganizationData(
            (localStorage.getItem(ORGANIZATION_ID) as string) || auth()?.account_id
          );
        } else if (res?.data.msg === 'Error! Snippet is not inserted Correctly') {
          setErrorMessage(res?.data.msg);
        }
      } else {
        setErrorMessage('Invalid site ID or URL');
      }
    } catch (e) {
      setErrorMessage('Snippet has not been inserted');
    }
  };

  useEffect(() => {
    if (isMobile) setPlatformShow(true);
  }, [isMobile]);

  const handleAnalyticsClick = () => {
    const siteId = query.get('siteId');
    const authData = localStorage.getItem('_auth');

    if (!siteId) {
      console.log('No siteId found in URL');
      return;
    }
    const subscription = profile.subscription_details?.subscription?.find(
      sub => sub.idsite === parseInt(siteId, 10)
    );

    if (subscription) {
      const { plan_name, plan, length } = subscription;
      const url = `${process.env.REACT_APP_API_URL}/index.php?module=Login&action=auth&hash=${auth()?.hash
        }&key=${authData}&idSite=${siteId}&account_id=${subscription.account_id}`;
      window.location.replace(url);
      return { plan_name, plan, length }; // Return these values for use in rendering
    } else {
      console.log('No matching subscription found for siteId:', siteId);
      return null;
    }
  };

  return (
    <>
      <div className="lg:w-[70%] w-full">
        <OnboardingProgressBar step={4} />
        {platformChangeShow ? (
          <PlaceholderForMobile />
        ) : (
          <>
            {verifySiteLoading ? (
              <div className="mb-3">
                <div className={'my-8 text-center'}>
                  <p className="text-3 text-black text-align-center">Verification in Progress</p>
                  <div className={'flex flex-col justify-center items-center'}>
                    <p className={'text-sm text-gray-400 w-1/2'}>
                      We&rsquo;re checking to make sure the snippets were installed correctly.
                    </p>
                    <div className={'my-14'}>
                      <LoadingSpinner color={'text-[#008160]'} />
                    </div>
                  </div>
                </div>
              </div>
            ) : snippetShow == 1 ? (
              <div className="mb-3">
                <p className="text-3 text-black my-8 text-center">
                  {trackingDetails.platform == 'woocommerce' ||
                    trackingDetails.platform == 'wordpress'
                    ? 'Add the WordPress/WooCommerce Plugin'
                    : 'Add Your Snippets'}
                </p>

                <div>
                  <div className={'flex flex-col gap-3'}>
                    {(trackingDetails.platform == 'woocommerce' ||
                      trackingDetails.platform == 'wordpress') && (
                        <WordpressWoocommerce trackingDetails={trackingDetails} />
                      )}
                    {(trackingDetails.platform == 'webflow' ||
                      trackingDetails.platform == 'squarespace') && (
                        <WebflowSquarespace trackingDetails={trackingDetails} />
                      )}
                    {trackingDetails.platform == 'bigcommerce' && (
                      <BigCommerce trackingDetails={trackingDetails} />
                    )}
                    {trackingDetails.platform == 'other' && (
                      <OtherPlatforms trackingDetails={trackingDetails} />
                    )}
                    {trackingDetails.platform == 'shopify' && (
                      <Shopify trackingDetails={trackingDetails} />
                    )}
                  </div>
                </div>
              </div>
            ) : message || verifySiteError || snippetShow == 2 ? (
              <div className={'flex flex-col justify-center items-center text-center'}>
                <div className={'mt-8 text-center'}>
                  <div className={'flex flex-col justify-center items-center'}>
                    {message && selectedPageBuilderStatus == 'No' && (
                      <>
                        <div className={'mt-8 text-center'}>
                          <p className="text-3 text-black text-align-center">Verification Successful</p>
                        </div>

                        <div className='flex flex-col justify-center'>
                          <div className={'mt-10 mb-5 flex gap-2 justify-start items-center'}>
                            <span className=''>
                              <img src={'/assets/checkmark.svg'} className={'w-6'} alt={''} />
                            </span>
                            <span className={'text-base font-medium text-[#1A2128]'}>
                              Header Snippet
                            </span>
                          </div>

                          <div className={'mb-10 flex gap-2  justify-start items-center'}>
                            <span className=''>
                              <img src={'/assets/checkmark.svg'} className={'w-6'} alt={''} />
                            </span>
                            <span className={'text-base font-medium text-[#1A2128]'}>
                              Checkout Snippet
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    {message && selectedPageBuilderStatus == 'Yes' && (
                      <ThirdPartyBuilder trackingDetails={trackingDetails} />
                    )}
                    {verifySiteError && (
                      <>
                        <div className={'mt-8 text-center'}>
                          <p className="text-3 text-black text-align-center">Verification Unsuccessful</p>
                        </div>

                        <div className='flex flex-col justify-center'>
                          <div className={'mt-10 mb-5 flex gap-2 justify-start items-center'}>
                            <span className='border border-[#FF4A3D] rounded-full p-1'>
                              <img src={'/assets/x-02-filled.svg'} className={'w-4'} alt={''} />
                            </span>
                            <span className={'text-base font-medium text-[#FF4A3D]'}>
                              Header Snippet
                            </span>
                          </div>

                          <div className={'mb-10 flex gap-2  justify-start items-center'}>
                            <span className='border border-[#FF4A3D] rounded-full p-1'>
                              <img src={'/assets/x-02-filled.svg'} className={'w-4'} alt={''} />
                            </span>
                            <span className={'text-base font-medium text-[#FF4A3D]'}>
                              Checkout Snippet
                            </span>
                          </div>
                        </div>

                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {!verifySiteLoading && !message ? (
              <div className={'flex flex-col items-center mt-5'}>
                <div className={'w-full md:w-[60%] grid gap-3'}>
                  {snippetShow === 1 ? (
                    <>
                      <button
                        type={'button'}
                        disabled={verifySiteLoading}
                        onClick={handleVerifySite}
                        className={`rounded-md px-3 py-3 ${verifySiteLoading
                            ? 'bg-[#F6F6F6] text-gray-600'
                            : ' bg-[#008160] hover:bg-emerald-600 text-white '
                          } w-full font-medium text-sm`}>
                        {message ? 'Next' : 'Verify Website'}
                      </button>
                      {!message && (
                        <button
                          type={'button'}
                          onClick={() => {
                            changeStep(3);
                            setSnippetShow(1);
                          }}
                          className={
                            'px-3 py-2 bg-transparent text-[#008160] w-full font-medium text-sm'
                          }>
                          Go Back
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      <button
                        type={'button'}
                        onClick={() => setSnippetShow(1)}
                        className={'px-3 py-3 bg-[#008160] hover:bg-emerald-600 text-white w-full font-medium text-sm rounded-md'}>
                        Go Back
                      </button>
                      <Link to={routes.DASHBOARD} className="text-[#008160] hover:text-emerald-600 w-full font-medium text-center mt-3">
                        Skip for now
                      </Link>

                    </>
                  )}
                </div>

                {(trackingDetails.platform == 'woocommerce' ||
                  trackingDetails.platform == 'wordpress') && <InstallationHelp />}
              </div>
            ) : !verifySiteLoading ? (
              <div className={'flex justify-center'}>
                {(() => {
                  const siteId = query.get('siteId');
                  const subscription = profile.subscription_details?.subscription?.find(
                    sub => siteId !== null && sub.idsite === parseInt(siteId, 10)
                  );

                  if (subscription) {
                    const { plan_name } = subscription;

                    if (plan_name === "Freemium") {
                      return (
                        <button
                          onClick={handleAnalyticsClick}
                          className={'px-3 py-3 bg-[#008160] hover:bg-emerald-600 text-white w-[50%] font-medium text-sm rounded-md'}
                        >
                          <span> Done </span>
                        </button>
                      );
                    } else {
                      return (
                        <div className='flex justify-center'>
                          <Link
                            to={routes.WEBSITES}
                            className={' hover:bg-emerald-600 bg-[#008160] px-5 py-3 rounded w-full text-white text-sm flex items-center gap-2'}
                          >
                            <span>Done </span>
                          </Link>
                        </div>
                      );
                    }
                  }

                  // Default case if no subscription found
                  return null;
                })()}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

export function PlaceholderForMobile() {
  return (
    <div className="mb-3">
      <div className={'my-8 text-center'}>
        <p className="text-3 text-black text-align-center">Continue on Desktop</p>
        <div className={'flex flex-col justify-center items-center'}>
          <p className={'text-sm text-gray-700 w-full'}>
            Use a desktop computer to place the heatmap.com code snippet onto your site.
          </p>
          <div className={'mt-14 flex justify-center'}>
            <img alt={''} src={'/assets/desktop-placeholder.png'} className={'w-4/5'} />
          </div>
        </div>
      </div>
    </div>
  );
}