import React, { useContext, useState, useEffect } from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { usePayment } from '../../../pages/main/Pricing/usePayment';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { useAuthUser } from 'react-auth-kit';
import { ORGANIZATION_ID, SUBSCRIPTION_ID_KEY, AMPLITUDE_EVENTS } from '../../../constants';
import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Error from '../../../components/global/ui/alerts/Error';
import Modal from '../../../components/global/ui/Modal';
import { trackAmplitudeEvent } from '../../../util/amplitudeService';
import { IPackage, IPlanInterval } from '../../../interfaces/components';
import { IPaymentCard, ISubscription } from '../../../interfaces/subscription';
import { routes } from '../../../constants/routes';
import { useUserSites } from '../../../hooks/useUserSites';

interface AddPaymentCardProps {
  closeModal: () => void;
  customerId: string;
  onCardAdded?: () => void;
  plan: IPackage;
  interval: IPlanInterval;
  subscription: ISubscription;
  isNewCardAdded: boolean;
  onCancel: () => void;
}

export default function AddAndPaymentCard({
  closeModal,
  customerId,
  subscription,
}: AddPaymentCardProps) {
  const { addPaymentCard, loading, errorMessage, makePayment, addCardToSubscription } = usePayment();
  const { fetchOrganizationData, selectedOrganization, profile } = useContext(OrganizationContext);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null | undefined>(null);
  const [selectedCard, setSelectedCard] = useState<IPaymentCard | null | undefined>(
    profile?.subscription_details?.card.find((item) => item.is_default_source === 1)
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { loadUserProfile } = useUserSites();

  const [success, setSuccess] = useState(false)
  const navigate = useNavigate();
  const auth = useAuthUser();

  const validationSchema = Yup.object().shape({
    number: Yup.string()
      .max(16)
      .min(14, 'Card number must be at least 14 characters')
      .trim()
      .required('Enter your card number'),
    expMonth: Yup.string()
      .typeError('Expiry month must be digits 01 - 12')
      .max(2, 'Expiry month must be at most 2 characters')
      .min(1, 'Expiry month must be at least 1 character')
      .required('Enter expiry month'),
    expYear: Yup.string()
      .typeError('Expiry year must be digits')
      .max(2, 'Expiry year must be at most 2 characters')
      .min(2, 'Expiry year must be at least 2 characters')
      .required('Enter expiry year'),
    name: Yup.string().required('Name on card is required'),
    cvc: Yup.string().max(4).required('CVC is required'),
    plan: Yup.string().required('Select your plan'),
    email: subscription?.card?.length > 0
      ? Yup.string().email('Enter a valid email')
      : Yup.string().email('Enter a valid email').required('Enter your email'),
    interval: Yup.string().required('Specify the interval'),
    coupon: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      number: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      addressOne: '',
      addressTwo: '',
      city: '',
      country: '',
      plan: localStorage.getItem('selectedPlanKey'),
      email: localStorage.getItem('email'),
      interval: localStorage.getItem('selectedInterval') || 'month',
      coupon: '',
      card_id:
      subscription.card && subscription.card.length > 0
        ? subscription.card.filter((item) => item.is_default_source)[0].id
        : ''
    },
    validationSchema,
    // onSubmit: async (values) => handleAddCardAndSubscribe(values),
    onSubmit: async (values) => {
        console.log("Form submitted", values);
        await handleAddCardAndSubscribe(values);
      },
  });

  const handleAddCardAndSubscribe = async (values: FormikValues) => {
    setMessage(null);
    setError(null);
    setIsSubmitting(true);
  
    const response = await addPaymentCard({
      name: values.name,
      number: values.number,
      expMonth: values.expMonth,
      expYear: values.expYear,
      cvc: values.cvc,
      addressOne: values.addressOne,
      addressTwo: values.addressTwo,
      country: values.country,
      city: values.city,
      customerId: customerId,
      account_id: (localStorage.getItem(ORGANIZATION_ID) as string) || auth()?.account_id,
    });
  
    if (response?.result === 'success') {
      setMessage(response.msg);
      trackAmplitudeEvent(AMPLITUDE_EVENTS.CARD_ADDED_TO_ACCOUNT);
      setShouldRefresh(true);
      await handleSubscription(values);
    setSuccess(true)
  
    } else {
      setError('Error adding payment card');
      setIsSubmitting(false);
    }
  };
/* 
  useEffect(() => {
    const fetchProfileAndContinue = async () => {
      try {
        const userProfile = await loadUserProfile({
          email: auth()?.email,
          account_id: localStorage.getItem(ORGANIZATION_ID) as string
        });
        console.log("Updated User Profile:", userProfile);

        if (userProfile && userProfile.subscription_details && userProfile.subscription_details.card) {
          console.log("Card successfully added to the profile");
          await handleSubscription(formik.values);
        } else {
          console.log("Card not found in the updated profile");
          setError("Card addition failed. Please try again.");
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setError("Failed to verify card addition. Please check your account.");
        setIsSubmitting(false);
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const keepModalOpen = urlParams.get('keepModalOpen');

    if (keepModalOpen === 'true') {
      fetchProfileAndContinue();
      // Remove the query parameter
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('keepModalOpen');
      window.history.replaceState({}, document.title, newUrl.toString());
    }
  }, [success]); */
  
  
  const handleSubscription = async (values: FormikValues) => {
    const createCustomerResponse = await makePayment({
      name: values.name,
        email: values.email,
        plan: values.plan,
        card_id: selectedCard
          ? selectedCard.id
          : subscription.card.find((item) => item.is_default_source)?.id,
        interval: values.interval,
        coupon: values.coupon,
        account_id: selectedOrganization && selectedOrganization.account_id
    });
  
    if (createCustomerResponse?.result === 'success') {
      const { subscriptionId } = createCustomerResponse.additional;
      if (values.card_id) {
        localStorage.setItem(SUBSCRIPTION_ID_KEY, subscriptionId);
        setTimeout(() => {
          fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
          navigate(routes.SETUP);
        }, 10000);
      } else {
        const addCard = await addCardToSubscription(subscriptionId);
        if (addCard?.result === 'success') {
          localStorage.setItem(SUBSCRIPTION_ID_KEY, subscriptionId);
          setTimeout(() => {
            fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
            navigate(routes.SETUP);
          }, 10000);
        }
      }
    } else {
      // setError('Error completing subscription');
      setIsSubmitting(false);
    }

  };

  

  return (
    <Modal closeModal={closeModal} title={'Add Payment Card'} width={'w-full lg:w-[650px]'}>
      {loading || isSubmitting ? (
        <div className="flex flex-col justify-center items-center p-5">
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}> {shouldRefresh ? 'Processing your subscription...' : 'Creating account...'}</h5>
          {errorMessage && <Error value={errorMessage} />}
          {error && <Error value={error} />}
        </div>
      ) : (
        <div className='py-6'>
           <form
                onSubmit={(event) => {
                  event.preventDefault();
                  formik.handleSubmit();
                }}
                id={'new_payment_card-form'}
                className={'grid gap-2'}>
                <div>
                  <label className={'text-sm'}>Name on card</label>
                  <input
                    type={'text'}
                    name={'name'}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    autoFocus={true}
                    className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                  />
                  <p className={'text-xs text-red-500'}>
                    {formik.touched.name && formik.errors.name}
                  </p>
                </div>

                <div>
                  <label className={'text-sm'}>Card details</label>
                  <div className={'mt-1 border border-gray-300 rounded grid grid-cols-12 gap-2'}>
                    <div className={'col-span-7 flex items-center '}>
                      <span className={'pl-2 pt-1'}>
                        <i className="fi fi-ss-credit-card text-xl text-gray-300"></i>
                      </span>
                      <input
                        type={'text'}
                        name={'number'}
                        maxLength={16}
                        minLength={15}
                        value={formik.values.number}
                        onChange={formik.handleChange}
                        placeholder={'Card number'}
                        className={'outline-0 w-full p-2 text-sm'}
                      />
                    </div>
                    <div className={'col-span-3 flex items-center'}>
                      <input
                        type={'text'}
                        name={'expMonth'}
                        value={formik.values.expMonth}
                        onChange={formik.handleChange}
                        placeholder={'MM'}
                        minLength={2}
                        maxLength={2}
                        className={'outline-0 w-full py-2 text-sm text-center'}
                      />
                      {'/'}
                      <input
                        type={'text'}
                        name={'expYear'}
                        minLength={2}
                        maxLength={2}
                        placeholder={'YY'}
                        value={formik.values.expYear}
                        onChange={formik.handleChange}
                        className={'outline-0 w-full py-2 text-sm text-center'}
                      />
                    </div>
                    <div className={'col-span-2'}>
                      <input
                        type={'text'}
                        name={'cvc'}
                        minLength={3}
                        maxLength={
                          formik.values.number.length === 14 || formik.values.number.length === 16
                            ? 3
                            : 4
                        }
                        value={formik.values.cvc}
                        onChange={formik.handleChange}
                        placeholder={'CVC'}
                        className={'outline-0 w-full p-2 text-sm text-center'}
                      />
                    </div>
                  </div>
                  <p className={'text-xs text-red-500'}>
                    {(formik.touched.number && formik.errors.number) ||
                      (formik.touched.expMonth && formik.errors.expMonth) ||
                      (formik.touched.expMonth && formik.errors.expMonth) ||
                      (formik.touched.cvc && formik.errors.cvc)}
                  </p>
                </div>



                <div className={'mt-2'}>
                  <PrimaryButton
                    disabled={loading}
                    title={loading ? 'Adding new card...' : 'Add card'}
                    handler={() => {
                        console.log("Button clicked");
                        formik.submitForm();
                      }}
                  />
                </div>

                

                {errorMessage && <Error value={errorMessage} />}
              </form>
        </div>
      )}
    </Modal>
  );
}
