import { useEffect, useRef } from 'react';
import useClickOutside from '../../../../hooks/useClickOutside';
import { IClient, IRegistration } from '../../../../interfaces/client';

export default function ClientCardMenuPopover({
  data,
  onClickOutside,
  setCancelModalOpen,
  setDeleteModalOpen,
  setActivateModalOpen,
  setEditModalOpen,
  setSendEmailModalOpen,
  setChangeStatusModalOpen,
  show
}: {
  data: IClient | IRegistration;
  setCancelModalOpen: (x: boolean) => void;
  setDeleteModalOpen: (x: boolean) => void;
  setActivateModalOpen: (x: boolean) => void;
  setEditModalOpen: (x: boolean) => void;
  setSendEmailModalOpen: (x: boolean) => void;
  setChangeStatusModalOpen: (x: boolean) => void;
  onClickOutside: () => void;
  show: boolean;
}) {
  const ref: any = useRef(null);
  useClickOutside(ref, onClickOutside);

  if (!show) return null;

  useEffect(() => {}, []);

  function instanceOfIClient(object: any): object is IClient {
    return 'login' in object;
  }

  function instanceOfIRegistration(object: any): object is IRegistration {
    return 'ver_code' in object;
  }

  return (
    <>
      <div
        ref={ref}
        className={
          'absolute top-5 lg:top-10 right-0 bg-white z-50 shadow-lg w-[45vw] lg:w-[15vw] '
        }>
        <div className={'grid'}>
          {instanceOfIRegistration(data) && (
            <button
              type={'button'}
              onClick={() => {
                setEditModalOpen(true);
                onClickOutside();
              }}
              className={
                'option z-10 w-full px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="fi fi-rr-pencil text-sm md:text-base"></i>
              </span>
              <span>Edit</span>
            </button>
          )}
          {/*{instanceOfIClient(data) && (*/}
          <button
            type={'button'}
            onClick={() => {
              setSendEmailModalOpen(true);
              onClickOutside();
            }}
            className={'option px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'}>
            <span>
              <i className="fi fi-rs-at text-sm md:text-base"></i>
            </span>
            <span>Send email</span>
          </button>
          {/*)}*/}
          {instanceOfIRegistration(data) && (
            <button
              type={'button'}
              onClick={() => {
                setActivateModalOpen(true);
                onClickOutside();
              }}
              className={
                'option px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="fi fi-rr-badge-check text-sm md:text-lg"></i>
              </span>
              <span>Activate account</span>
            </button>
          )}
          {instanceOfIClient(data) && data.status == 'active' ? (
            <button
              type={'button'}
              onClick={() => {
                setChangeStatusModalOpen(true);
                onClickOutside();
              }}
              className={
                'option px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="fi fi-rs-ban text-sm md:text-base"></i>
              </span>
              <span>Deactivate account</span>
            </button>
          ) : instanceOfIClient(data) && data.status == 'inactive' ? (
            <button
              type={'button'}
              onClick={() => {
                setChangeStatusModalOpen(true);
                onClickOutside();
              }}
              className={
                'option px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="fi fi-rr-badge-check text-sm md:text-base"></i>
              </span>
              <span>Activate account</span>
            </button>
          ) : null}
          {instanceOfIRegistration(data) && (
            <button
              type={'button'}
              onClick={() => {
                setCancelModalOpen(true);
                onClickOutside();
              }}
              className={
                'option px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="fi fi-rr-trash text-sm md:text-lg"></i>
              </span>
              <span>Cancel registration</span>
            </button>
          )}
          {instanceOfIClient(data)  && data.has_permission && (
            <button
              type={'button'}
              onClick={() => {
                setDeleteModalOpen(true);
                onClickOutside();
              }}
              className={
                'option px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="fi fi-rr-trash text-sm md:text-lg"></i>
              </span>
              <span>Delete client</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
